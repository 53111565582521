let ifloaded = false;
$(window).on("loadMenuFunctionality", function () {
  menufunctionalty();
});
$(window).on("locationchange", function () {
  if (!ifloaded) {
    if (!window.location.pathname.includes("login")) ifloaded = true;
  }
  $(".dropdown-item").removeClass("active");
  $(".nav-item").removeClass("active");
  let currentLocation = window.location.pathname;
  if ($($('[href="' + currentLocation + '"]')).length > 0) {
    sessionStorage.setItem("routerLinkActive", currentLocation);
  } else {
    sessionStorage.setItem("routerLinkActive", currentLocation);
    currentLocation = sessionStorage.getItem("routerLinkActive") || "";
  }
  setTimeout(() => {
    $('[href="' + currentLocation + '"]')
      .parent(".nav-item")
      .addClass("active");
    $('[href="' + currentLocation + '"].dropdown-item').addClass("active");
    $('[href="' + currentLocation + '"].dropdown-item')
      .parent(".dropdown-menu")
      .parent(".nav-item")
      .addClass("active");
    $(".cui-menu-left-list li").removeClass("active");
    $(".subMenuSelected")
      .addClass("subMenuNotSelected")
      .removeClass("subMenuSelected");
    $('[href="' + currentLocation + '"].dropdown-item').addClass("active");
    $('[href="' + currentLocation + '"].dropdown-item')
      .parent(".dropdown-menu")
      .parent(".nav-item")
      .addClass("active");
    $('[href="' + currentLocation + '"]')
      .parent("li")
      .addClass("active");
    $('[href="' + currentLocation + '"]')
      .parent("li")
      .parent("ul")
      .parent("li")
      .addClass("active");
    $('[href="' + currentLocation + '"]')
      .parent("li")
      .parent("ul")
      .parent("li")
      .addClass("active");
    $('[href="' + currentLocation + '"]')
      .parent("li")
      .parent("ul")
      .parent("li")
      .find("a")
      .find(".subMenuNotSelected")
      .addClass("subMenuSelected")
      .removeClass("subMenuNotSelected");
    $('[href="' + currentLocation + '"]')
      .parent("li")
      .parent("ul")
      .parent("li")
      .parent("ul")
      .parent("li")
      .addClass("active");
  }, 200);
  $(".cui-menu-left-list li").removeClass("active");
  $(".subMenuSelected")
    .addClass("subMenuNotSelected")
    .removeClass("subMenuSelected");
  $('[href="' + currentLocation + '"].dropdown-item').addClass("active");
  $('[href="' + currentLocation + '"].dropdown-item')
    .parent(".dropdown-menu")
    .parent(".nav-item")
    .addClass("active");
  $('[href="' + currentLocation + '"]')
    .parent("li")
    .addClass("active");
  $('[href="' + currentLocation + '"]')
    .parent("li")
    .parent("ul")
    .parent("li")
    .addClass("active");
  $('[href="' + currentLocation + '"]')
    .parent("li")
    .parent("ul")
    .parent("li")
    .addClass("active");
  $('[href="' + currentLocation + '"]')
    .parent("li")
    .parent("ul")
    .parent("li")
    .find("a")
    .find(".subMenuNotSelected")
    .addClass("subMenuSelected")
    .removeClass("subMenuNotSelected");
  $('[href="' + currentLocation + '"]')
    .parent("li")
    .parent("ul")
    .parent("li")
    .parent("ul")
    .parent("li")
    .addClass("active");
});

function menufunctionalty() {
  $(".subMenu-link").click(function () {
    if ($(this).parent().find("ul.child-submenu").is(":visible")) {
      $(this).parent().find("ul.child-submenu").hide();
      $(".subMenuSelected")
        .addClass("subMenuNotSelected")
        .removeClass("subMenuSelected");
    } else {
      $(".child-submenu").hide();
      $(this).parent().find("ul.child-submenu").toggle();
      $(".subMenuSelected")
        .addClass("subMenuNotSelected")
        .removeClass("subMenuSelected");
      $(this)
        .parent()
        .find(".subMenuNotSelected")
        .removeClass("subMenuNotSelected")
        .addClass("subMenuSelected");
    }
  });

  $(".main-menu-link").click(function () {
    if (!$(this).parent().hasClass("hasMenu")) {
      $(".child-submenu").hide();
    }
  });
}

function clearlisteners(eventName) {
  window.removeAllListeners(eventName);
}
